<mat-form-field appearance="fill">
  <mat-label> {{ label }}</mat-label>
  <mat-select
    [formControl]="control"
    [multiple]="multiSelect"
    id="userInput"
    >
    @if (selectAllOptionVisible) {
      <mat-checkbox
        class="custom-checkbox"
        (change)="toggleSelection($event)"
        [checked]="optionList.length === control.value?.length"
        color="primary"
        >
        {{ 'interface.all' | translate }}</mat-checkbox
        >
      }

      @for (option of optionList; track optionListTrackByFn($index, option)) {
        <mat-option
          [value]="option.value"
          [disabled]="option.disabled"
      [matTooltip]="
        (option.showTooltip && option.tooltipTranslationKey) || '' | translate
      "
          [matTooltipDisabled]="!option.showTooltip"
          >{{ option.label }}</mat-option
          >
        }
      </mat-select>
    </mat-form-field>
