
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { isValueChanged } from '../../shared/util/simple-changes.util';
import { SortDirection } from './sort-config.model';

@Component({
  selector: 'app-sort-config',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './sort-config.component.html',
  styleUrls: ['./sort-config.component.scss'],
})
export class SortConfigComponent implements OnChanges {
  @Input() sortDirection: SortDirection = SortDirection.NONE;
  @Output() sortChange = new EventEmitter<SortDirection>();

  iconTitlePropKey = 'interface.sorting.none';
  iconClass = 'up-down';
  _sortDirection: SortDirection | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sortDirection'] && isValueChanged(changes['sortDirection'])) {
      this.setupProperties(changes['sortDirection'].currentValue);
    }
  }
  updateSortDirection() {
    let direction = SortDirection.NONE;
    if (this._sortDirection === SortDirection.NONE) {
      direction = SortDirection.ASC;
    } else if (this._sortDirection === SortDirection.ASC) {
      direction = SortDirection.DESC;
    }

    this.setupProperties(direction);
    this.sortChange.emit(direction);
  }

  setupProperties(direction: SortDirection) {
    this._sortDirection = direction;
    if (this._sortDirection === SortDirection.ASC) {
      this.iconTitlePropKey = 'interface.sorting.descending';
      this.iconClass = 'down';
    } else if (this._sortDirection === SortDirection.DESC) {
      this.iconTitlePropKey = 'interface.sorting.none';
      this.iconClass = 'up';
    } else {
      this.iconTitlePropKey = 'interface.sorting.ascending';
      this.iconClass = 'up-down';
    }
  }
}
