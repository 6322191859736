@if (loadingIndicatorService.show) {
  <div
    elTestId="LWFgK3j3H3JeC1y2gogjy"
    class="e-container"
    >
    <div
      elTestId="F6_Ry9uo7izHXT3w6CqC8"
      class="loading-container"
      >
      <div
        elTestId="6MBtEQzrW43yDkrJq6OgI"
        class="loading-item"
        >
        <h4 elTestId="WN0DHuBk_aaltX4NnLZSr">
          {{ loadingIndicatorService.message }}
        </h4>
      </div>
      <div
        elTestId="gWgGmx5Y6yEOIDsKG3hqO"
        class="loading-item"
        >
        <div
          elTestId="A6tjMz_BYZ1YYB6sz-pJg"
          class="a-progress-indicator -indeterminate"
          id="random-progress"
          role="progressbar"
          aria-label="progress bar"
          aria-valuemin="0"
          aria-valuemax="100"
          tabindex="0"
          >
          <div
            elTestId="DXd4D99Osh347Pf7LH30-"
            class="a-progress-indicator__anim-bar"
            >
            <div
              elTestId="hQLqyFOX4MMlkXISFWLb3"
              class="a-progress-indicator__inner-bar"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
