
import { Component } from '@angular/core';
import { interval, Subscription, takeUntil, timer } from 'rxjs';
@Component({
  selector: 'chatbot-typing-effect',
  standalone: true,
  imports: [],
  templateUrl: './typing-effect.component.html',
  styleUrls: ['./typing-effect.component.scss'],
})
export class TypingEffectComponent {
  dynamicText = ''; // The animated dots
  typingSubscription: Subscription | undefined; // Subscription for the typing animation

  ngOnInit() {
    this.typingSubscription = this.typeText();
  }

  ngOnDestroy() {
    this.stopTypingEffect();
  }

  stopTypingEffect() {
    if (this.typingSubscription) {
      this.typingSubscription.unsubscribe();
    }
  }

  typeText() {
    return interval(200)
      .pipe(takeUntil(timer(4000)))
      .subscribe(() => {
        this.dynamicText += '.';
      });
  }
}
