<div
  class="a-box -floating-shadow-s chat-wrapper"
  [ngStyle]="{ display: isLoading ? 'none' : 'flex' }"
>
  <div class="action-menu blur-border">
    <app-vertical-action-menu></app-vertical-action-menu>
  </div>
  <div class="chat-container">
    <div class="chat-close-button-container">
      <span class="welcome-msg">Ask your AI chatbot</span>
      <button
        type="button"
        class="a-button a-button--integrated -without-label chat-close"
        aria-label="close"
        title="{{ 'common.close' | translate }}"
        (click)="closed.emit(true)"
      >
        <i class="a-icon a-button__icon ui-ic-close"></i>
      </button>
    </div>

    <div
      class="messages"
      [class.messages--none]="(messages$ | async)?.length === 0"
      #messagesContainer
    >
      @if ((messages$ | async)?.length === 0) {
        <div class="messages-hint">
          <div class="messages-hint-role">
            <ng-container *ngTemplateOutlet="userProfile"></ng-container>
            <div [innerHTML]="chatIntroText$ | async"></div>
          </div>
        </div>
      }
      @for (message of messages$ | async; track message) {
        <div
          [ngClass]="{
            'message--user': message.role === 'user',
            'message--assistant': message.role === 'assistant'
          }"
        >
          <div class="message--role">
            @if (message.role === 'user') {
              <ng-container *ngTemplateOutlet="userProfile"></ng-container>
            } @else {
              <i
                class="a-icon boschicon-bosch-ic-user-robot-head chat-icon"
                title="user-robot-head"
              ></i>
            }
          </div>
          <div
            class="message--content"
            [innerHTML]="formatResponse(message) | formatText"
          ></div>
        </div>
      }
      @if (chatbotService.loading | async) {
        <div class="message--assistant">
          <div class="message--role">
            <ng-container *ngTemplateOutlet="assistantProfile"></ng-container>
          </div>
          <div class="message--content">
            <chatbot-typing-effect></chatbot-typing-effect>
          </div>
        </div>
      }
    </div>
    <div class="user-input-container">
      <app-user-query-input
        [disabled]="(chatbotService.loading | async)!"
        [maxLength]="maxUserInput"
        (userInput)="onUserInput($event)"
      ></app-user-query-input>
    </div>
  </div>
</div>
<ng-template #userProfile>
  <i
    class="a-icon boschicon-bosch-ic-my-brand-frame chat-icon"
    title="user-profile"
  ></i>
</ng-template>
<ng-template #assistantProfile>
  <i
    class="a-icon boschicon-bosch-ic-user-robot-head chat-icon"
    title="user-robot-head"
  ></i>
</ng-template>
