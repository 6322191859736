
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-user-query-input',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './user-query-input.component.html',
  styleUrls: ['./user-query-input.component.scss'],
})
export class UserQueryInputComponent implements OnInit {
  @Input() disabled!: boolean;
  @Input() maxLength!: number;
  @Output() userInput = new EventEmitter<string>();
  inputControl = new FormControl({ value: '', disabled: false });

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}
  ngOnInit() {
    this.inputControl.valueChanges.subscribe((value) => {
      if (value && value.length > this.maxLength) {
        this.inputControl.setValue(value.substring(0, this.maxLength), {
          emitEvent: false,
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('disabled' in changes) {
      const newDisabledValue = changes['disabled'].currentValue;
      if (newDisabledValue) {
        this.inputControl.disable();
      } else {
        this.inputControl.enable();
        this.focusOnInput();
      }
    }
  }

  emitValue(value: string) {
    if (this.disabled) {
      return;
    }
    this.userInput.emit(value);
    this.inputControl.reset();
  }

  onEnterPressed() {
    this.emitValue(this.inputControl.value || '');
  }

  private focusOnInput() {
    // Use Renderer2 to focus on the input control
    this.renderer
      .selectRootElement(
        this.elementRef.nativeElement.querySelector('textarea')
      )
      .focus();
  }
  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '3rem'; // Reset the height to its initial value before resizing
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height based on content
  }
}
