<div #sideMenuExpandedContainerRef></div>
@if ((menuService.sideMenuExpanded$ | async) === false) {
  <ng-container *ngTemplateOutlet="sideMenuNavigationRef"></ng-container>
}

<ng-template #sideMenuNavigationRef>
  <nav
    elTestId="btXTtm_YAPQKVKLJe8QjE"
    id="sd-side-navigation"
    class="m-side-navigation -contrast"
    [ngClass]="{ '-open': (menuService.sideMenuExpanded$ | async) }"
    aria-label="Side Navigation"
    aria-hidden="false"
    >
    <div
      elTestId="6obd-nUa_DYzp6c0OeNzE"
      class="m-side-navigation__header"
      >
      <div
        elTestId="uKPr_0KCZc7yN2Es15iz8"
        class="m-side-navigation__header__label -size-l highlight"
        >
        Navigation
      </div>
      <button
        elTestId="wr6tulK_1skXC1dif3zWG"
        [ngStyle]="{
          display: (menuService.sideMenuExpanded$ | async) ? 'none' : 'flex'
        }"
        type="button"
        class="a-button a-button--integrated -without-label m-side-navigation__header__trigger"
        aria-haspopup="false"
        aria-label="Open Side Navigation"
        tabindex="{{ (menuService.sideMenuExpanded$ | async) ? '-1' : '0' }}"
        (click)="expandSideMenu()"
        >
        <em
          elTestId="BpTdbPZ2IkwbrgBMwEpfj"
          class="a-icon a-button__icon boschicon-bosch-ic-list-view-mobile"
          title="Open navigation"
        ></em>
      </button>
      <button
        elTestId="U4bDrQbwbTYpmYKKu9Mu4"
        type="button"
        class="a-button a-button--integrated -without-label m-side-navigation__header__trigger"
        aria-haspopup="false"
        aria-label="Close Side Navigation"
        tabindex="{{ (menuService.sideMenuExpanded$ | async) ? '0' : '-1' }}"
        (click)="collapseSideMenu()"
        >
        <em
          elTestId="QT_hhrTU47y5SaSeHTQ6P"
          class="a-icon a-button__icon boschicon-bosch-ic-close"
          title="Close navigation"
        ></em>
      </button>
    </div>
    <div
      elTestId="MTFAapZSOuJzLuiDF4yqs"
      class="nav-flex"
      >
      <div
        elTestId="aWDfJTeZrdqUrpvn5giqX"
        class="nav-section"
        >
        <ul
          elTestId="dWrNyIVN60IIwQnRYxgbK"
          class="m-side-navigation__menuItems"
          role="menubar"
          >
          @if ((authService.isUser$ | async) && !(isTenantManagementActive$ | async)) {
            <li
              elTestId="wtD06FkTq37nuNYUzT822"
              class="m-side-navigation__menuItem"
              role="none"
              (click)="homeIconClicked()"
              >
              <a
                elTestId="mc5Fu36_Td0WpMwniIAb5"
                [routerLink]="subscriptionHomeUrl"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="-selected"
                role="menuitem"
                class="m-side-navigation__link"
                >
                <em
                  elTestId="dsfB-vXYMqXvhFyT0FeOo"
                  class="a-icon boschicon-bosch-ic-home"
                  title="Home"
                ></em>
                <span
                  elTestId="JgT46W3g71Lbc1sdE7g5U"
                  class="m-side-navigation__label"
                  >{{ 'menu.home' | translate }}</span
                  >
                </a>
              </li>
            }
            @for (item of mappedMenuItems$ | async; track item) {
              <span
                elTestId="h28B_WDHpdEJztgBEQG5p"
                >
                @if (item.show | async) {
                  <li
                    elTestId="MkHX7aQmx1q_6faVfDAi0"
                    class="m-side-navigation__menuItem"
                    role="none"
                    >
                    <a
                      elTestId="I2KuEuLLnfyUs1NKyRMUe"
                      (click)="customNavigate(item)"
                      [id]="item.id"
                      role="menuitem"
                      class="m-side-navigation__link"
                [ngClass]="{
                  '-selected': location.pathname.includes(item.id)
                }"
                      >
                      <em
                        elTestId="QWQanw3jgODFECZSKuNuA"
                        class="a-icon {{ item.icon }}"
                  title="{{
                    selectedLanguage$ | async | i18nSelect: item.label
                  }}"
                      ></em>
                      {{ selectedLanguage$ | async | i18nSelect: item.label }}
                      <span
                        elTestId="sMOcH8x1MPj8sUF_FYOsK"
                        class="m-side-navigation__label"
                        >
                      </span>
                    </a>
                  </li>
                }
              </span>
            }
          </ul>
        </div>
        @if (!(isTenantManagementActive$ | async)) {
        <div
          elTestId="z61QE1tkFi4_JQG0rw8VO"
          class="nav-section"
          >
          <ul
            elTestId="b9yR_WEjMiwtWfRoAoNeF"
            class="m-side-navigation__menuItems"
            role="menubar"
            >
            @if (authService.isAdmin$ | async) {
              <li
                elTestId="Qy4p8PVLqNXW1u0srq0jj"
                class="m-side-navigation__menuItem -disabled"
                role="none"
                >
                <a
                  elTestId="NbFCo02BFVlQUQXqrQf_4"
                  role="menuitem"
                  class="m-side-navigation__link"
                  >
                  <em
                    elTestId="qOSIE7gQNmnJcD-jFFamH"
                    class="a-icon boschicon-bosch-ic-settings"
                    title="{{ 'menu.adminstration' | translate }}"
                  ></em>
                  <span
                    elTestId="eU1Io0pYgkAK_HJaBs7DP"
                    class="m-side-navigation__label"
                    >{{ 'menu.adminstration' | translate }}</span
                    >
                  </a>
                </li>
              }
              <hr
                elTestId="4UMgu45hLKayhOwKzWnw8"
                class="a-divider"
                />
              @if (authService.isAdmin$ | async) {
                <span
                  elTestId="E9r1f0pel_2iqvbktoGC2"
                  >
                  @for (item of adminMenu; track item) {
                    <li
                      elTestId="pEOdxk-CcD-QFiSN9sUNE"
                      class="m-side-navigation__menuItem"
                      role="none"
                      (click)="collapseSideMenu()"
                      >
                      <a
                        elTestId="gGkv7MsN_z-m76KiQ780i"
                        [routerLink]="item.route"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLinkActive="-selected"
                        role="menuitem"
                        class="m-side-navigation__link"
                        >
                        <em
                          elTestId="SGzBUE8p3C_id-tKPoVon"
                          class="a-icon {{ item.icon }}"
                          title="{{ item.label }}"
                        ></em>
                        <span
                          elTestId="qTxIHtNHHCMJFQ6yB6tK_"
                          class="m-side-navigation__label"
                          >{{ item.label }}</span
                          >
                        </a>
                      </li>
                    }
                  </span>
                }
                @if (authService.isOwner$ | async) {
                  <span
                    elTestId="hUoJ-A8xdOUJEDypanSaK"
                    >
                    @for (item of ownerMenu; track item) {
                      <li
                        elTestId="q-Zpx-KigddQlLnwvXQi2"
                        class="m-side-navigation__menuItem"
                        role="none"
                        (click)="collapseSideMenu()"
                        >
                        <a
                          elTestId="FEgcIpjW5I0UW3rBe_WFA"
                          [routerLink]="item.route"
                          [routerLinkActiveOptions]="{ exact: true }"
                          routerLinkActive="-selected"
                          role="menuitem"
                          class="m-side-navigation__link"
                          >
                          <em
                            elTestId="Eotw2_w1ORPTufBRVGW-8"
                            class="a-icon {{ item.icon }}"
                            title="{{ item.label }}"
                          ></em>
                          <span
                            elTestId="M9LlIPvlfFTMPCxFrndwf"
                            class="m-side-navigation__label"
                            >{{ item.label }}</span
                            >
                          </a>
                        </li>
                      }
                    </span>
                  }
                  @if (authService.isSystemAdmin$ | async) {
                    <span
                      elTestId="BCo5fv33F7BQcJptfqF1Y"
                      >
                      @for (item of systemAdminMenu; track item) {
                        <li
                          elTestId="haCa5gIEcafpjJTUYj1x3"
                          class="m-side-navigation__menuItem"
                          role="none"
                          (click)="collapseSideMenu()"
                          >
                          <a
                            elTestId="LyqawkptGKud73iZStMnZ"
                            [routerLink]="item.route"
                            [routerLinkActiveOptions]="{ exact: true }"
                            routerLinkActive="-selected"
                            role="menuitem"
                            class="m-side-navigation__link"
                            >
                            <em
                              elTestId="ccnTX4mZnoC34cWaGsZK3"
                              class="a-icon {{ item.icon }}"
                              title="{{ item.label }}"
                            ></em>
                            <span
                              elTestId="rj0L_RIzeubZ2_0D32-ew"
                              class="m-side-navigation__label"
                              >{{ item.label }}</span
                              >
                            </a>
                          </li>
                        }
                      </span>
                    }
                  </ul>
                </div>
        }
              </div>
            </nav>
          </ng-template>
