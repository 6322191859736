<div class="main-container">
  <div
    class="chat-window"
    [ngClass]="{ open: chatWindowIsActive, close: !chatWindowIsActive }"
    >
    <app-chat-window (closed)="toggleChat()"></app-chat-window>
  </div>
  @if (!chatWindowIsActive) {
    <span
      (click)="toggleChat()"
      class="chat-floating-widget"
      aria-label="accessibility label"
      >
      <i
        class="a-icon boschicon-bosch-ic-robot-onboarding-livechat"
        title="user-robot-head"
      ></i>
    </span>
  }
</div>
