<div class="a-text-field user-input-container">
  <div class="user-input-wrapper">
    <div class="a-text-area textarea-container">
      <textarea
        [formControl]="inputControl"
        (keydown.enter)="onEnterPressed()"
        [placeholder]="'copilot.userInput.placeholder' | translate"
        (input)="autoResize($event)"
      ></textarea>
    </div>
  </div>
  <button
    class="flex flex-row items-center justify-center a-button a-button--primary -without-label send-icon"
    (click)="onEnterPressed()"
    type="button"
    aria-label="accessibility label"
    [disabled]="disabled"
  >
    <i
      class="a-icon boschicon-bosch-ic-paperplane"
      title="paperplane"
    ></i>
  </button>
</div>
